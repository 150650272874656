<template>
  <CRow>
    <CCol col="12" xl="8">
      <transition name="slide">
      <CCard>
        <CCardHeader>
          Users
          <CButton size="sm" style="float:right;" color="success" @click="addUserModal = true">Add User</CButton>
        </CCardHeader>
        <CCardBody>
          <CAlert
            :show.sync="dismissCountDown"
            color="primary"
            fade
          >
            ({{dismissCountDown}}) {{ message }}
          </CAlert>
          <CDataTable
            :items="items"
            :fields="fields"
            :items-per-page="10"
            pagination
          >
          <template #status="{item}">
            <td>
              <CBadge :color="getBadge(item.status)">{{ item.status }}</CBadge>
            </td>
          </template>
          <template #show="{item}">
            <td>
              <CButton size="sm" color="primary" @click="showUser( item.id )">Show</CButton>
            </td>
          </template>
          <template #edit="{item}">
            <td>
              <CButton size="sm" color="primary" @click="editUser( item.id )">Edit</CButton>
            </td>
          </template>
          <template #delete="{item}">
            <td>
              <CButton size="sm" v-if="you!=item.id" color="danger" @click="deleteUser( item.id )">Delete</CButton>
            </td>
          </template>
        </CDataTable>
        </CCardBody>
      </CCard>
      </transition>

      <CModal
        title="Add User"
        :show.sync="addUserModal"
      >
        <CForm>
          <CInput
            :lazy="false"
            :value.sync="$v.addUserForm.name.$model"
            :isValid="checkIfValid('name')"
            placeholder="Name"
            autocomplete="given-name"
            invalidFeedback="This is a required field"
          />

          <CInput
            type="email"
            :lazy="false"
            :value.sync="$v.addUserForm.email.$model"
            :isValid="checkIfValid('email')"
            placeholder="Email"
            autocomplete="email"
            invalidFeedback="This is a required field and must be valid e-mail address"
          />

          <v-select 
            v-model="$v.addUserForm.user_type.$model"
            :options="['in_house', 'customer']"
            :isValid="checkIfValid('user_type')"
            placeholder="User Type"
          />

          <CInput
            type="text"
            style="margin-top:15px;"
            v-if="addUserForm.user_type === 'in_house'"
            :lazy="false"
            :value.sync="$v.addUserForm.csr_code.$model"
            :isValid="checkIfValid('csr_code')"
            placeholder="CSR Code"
            invalidFeedback="This is a required field."
          />

          <CInput
            :lazy="false"
            style="margin-top:15px;"
            v-if="addUserForm.user_type === 'customer'"
            :value.sync="$v.addUserForm.customer_number.$model"
            :isValid="checkIfValid('customer_number')"
            placeholder="Customer Number"
          />

          <CInput
            type="text"
            v-if="addUserForm.user_type === 'customer'"
            :lazy="false"
            :value.sync="$v.addUserForm.rep_number.$model"
            :isValid="checkIfValid('rep_number')"
            placeholder="Rep Number"
            invalidFeedback="This is a required field."
          />

          <v-select 
            v-if="csrOptions && addUserForm.user_type === 'customer'"
            v-model="$v.addUserForm.csr.$model"
            :options="csrOptions"
            label="name"
            value="id"
            :isValid="checkIfValid('csr')"
            placeholder="CSR"
          />

          <!-- <v-select 
            style='margin-top:15px;'
            v-model="$v.addUserForm.menu_roles.$model"
            :options="['admin', 'user admin', 'rep', 'rep admin']" 
            :isValid="checkIfValid('menu_roles')"
            placeholder="Menu Roles"
          /> -->
          <p class="mt-3">Roles</p>
          <div class="m-3 mt-2" v-if="roles">
            <CInputCheckbox
                v-for="rol in roles"
                :key="rol"
                :label="rol"
                name="selectRoles"
                :checked="addUserForm.menuroles.indexOf(rol) > -1"
                @update:checked="selectRadioSelectRole(rol)"
            />
          </div>
        </CForm>
        <template #footer>
          <CButton @click="addUserModal = false, reset()" color="danger">Cancel</CButton>
          <CButton @click="addUser()" color="success">Add</CButton>
        </template>
      </CModal>
    </CCol>
  </CRow>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import 'vue-select/dist/vue-select.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { required, minLength, email, sameAs, helpers } from "vuelidate/lib/validators"

export default {
  name: 'Users',
  data() {
    return {
      items: [],
      fields: ['id', 'name', 'email', 'roles', 'show', 'edit', 'delete'],
      currentPage: 1,
      perPage: 5,
      totalRows: 0,
      you: null,
      message: '',
      showMessage: false,
      dismissSecs: 7,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      addUserModal: false,
      addUserForm: this.getUserForm(),
      roles: null,
      csrOptions: null
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  mixins: [validationMixin],
  validations: {
    addUserForm: {
      name: {
        required
      },
      email: {
        required
      },
      user_type: {
        required
      },
      menuroles: {
        required
      },
      customer_number: {
        
      },
      rep_number: {
        
      },
      csr: {

      },
      csr_code: {

      }
    }
  },
  computed: {
    formString () { return JSON.stringify(this.addUserForm, null, 4) },
    isValid () { return !this.$v.addUserForm.$invalid },
    isDirty () { return this.$v.addUserForm.$anyDirty },
  },
  methods: {
    addUser() {
        let self = this;
        console.log(self.addUserForm)
        axios.post(   this.$apiAdress + '/api/register',
          self.addUserForm
        )
        .then(function (response) {
            console.log(response)
            self.items.push(response.data)
            self.addUserModal = false
            self.addUserForm = self.getUserForm()
        }).catch(function (error) {
            // if(error.response.data.message == 'The given data was invalid.'){
            //   self.message = '';
            //   for (let key in error.response.data.errors) {
            //     if (error.response.data.errors.hasOwnProperty(key)) {
            //       self.message += error.response.data.errors[key][0] + '  ';
            //     }
            //   }
            //   self.showAlert();
            // }
        });
    },
    selectRadioSelectRole(role){
      let temp = this.addUserForm.menuroles.indexOf(role); 
      if (temp > -1) {
        this.addUserForm.menuroles.splice(temp, 1);
      }else{
        this.addUserForm.menuroles.push(role);
      }
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    },
    getUserForm () {
      return {
        name: "",
        email: "",
        user_type: "",
        customer_number: "",
        csr: "",
        rep_number: "",
        csr_code: '',
        menuroles: []
      }
    },
    validate () {
      this.$v.$touch()
    },
    checkIfValid (fieldName) {
      const field = this.$v.addUserForm[fieldName]
      if (!field.$dirty) {
        return null
      } 
      return !(field.$invalid || field.$model === '')
    },

    reset () {
      this.addUserForm = this.getEmptyForm()
      this.submitted = false
      this.$v.$reset()
    },
    userLink (id) {
      return `users/${id.toString()}`
    },
    editLink (id) {
      return `users/${id.toString()}/edit`
    },
    showUser ( id ) {
      const userLink = this.userLink( id );
      this.$router.push({path: userLink});
    },
    editUser ( id ) {
      const editLink = this.editLink( id );
      this.$router.push({path: editLink});
    },
    deleteUser ( id ) {
      let self = this;
      let userId = id;
      axios.post( this.$apiAdress + '/api/users/' + id + '?token=' + localStorage.getItem("api_token"), {
        _method: 'DELETE'
      })
      .then(function (response) {
          self.message = 'Successfully deleted user.';
          self.showAlert();
          self.getUsers();
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    },
    countDownChanged (dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    getUsers (){
      let self = this;
      axios.get( this.$apiAdress + '/api/users?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        console.log('users')
        console.log(response)
        self.items = response.data.users;
        var arr = []
        response.data.users.forEach(function (val) {
          if (val.user_type === 'in_house') {
            arr.push(val)
          }
        })
        self.csrOptions = arr
        self.you = response.data.you;
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    },
    getRoles (){
      let self = this;
      axios.get(   this.$apiAdress + '/api/roles?token=' + localStorage.getItem("api_token") )
      .then(function (response) {
        self.roles = []
        response.data.forEach(function (val) {
          self.roles.push(val.name)
        })
      }).catch(function (error) {
        console.log(error);
        self.$router.push({ path: '/login' });
      });
    }
  },
  mounted: function(){
    this.getUsers();
    this.getRoles()
  }
}
</script>
